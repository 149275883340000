import React from 'react'
import MainMenu from './menu/main-menu'
import Logo from './logo'
import styled from 'styled-components'


const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.65);
  padding: 0 20px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
`



const Header = ({ siteTitle }) => (
  <StyledHeader>
    <Logo/>
    <MainMenu/>
  </StyledHeader>
)

export default Header