/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, keywords, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:locale`,
          content: `ru_ru`,
        },
        {
          property: `og:url`,
          content: `https://alexandramarkvart.ru`,
        },
        // {
        //   name: `twitter:card`,
        //   content: `summary`,
        // },
        // {
        //   name: `twitter:creator`,
        //   content: site.siteMetadata.author,
        // },
        // {
        //   name: `twitter:title`,
        //   content: title,
        // },
        // {
        //   name: `twitter:description`,
        //   content: metaDescription,
        // },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: ['профессиональная фотосессия в москве',
  'хайэнд ретушь',
  'профессиональная ретушь портрета',
  'high end ретушь',
  'студийный фотограф в москве',
  'журнальная ретушь',
  'студийная фотосессия в москве',
  'фотосессия в студии',
  'фотограф в москве',
  'лучший фотограф в москве',
  'лучший ретушер',
  'найти ретушера',
  'премиум фотосессия',
  'фотосессия на природе',
  'фотосессия на улице',
  'стрит съемка',
  'подводная фотосессия',
  'подводный фотограф',
  'рекламная съемка',
  'каталожная съемка',
  'как выбрать фотографа',
  'как найти фотографа',
  'портретный фотограф москва',
  'фотограф москва инстаграм',
  'фотограф девушка',],
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

export default SEO
