import React, { Component} from 'react'
import {StaticQuery, graphql} from 'gatsby'
import MenuItem from './components/menuItem'
import styled from 'styled-components'
import './menu.css'


const StyledMenu = styled.div`
    margin-right: 50px;
    display: flex;

    @media (max-width:767px){
        margin-right: 0px;
    }
    @media (min-width:768px) and (max-width:991px){
        margin-right: 0px;
    }
`


class HamburgerMenu extends Component{
    constructor(props){
        super(props)
        this.state = {
            is_open: false
        }
        
        this.toggleNavMenu = this.toggleNavMenu.bind(this)
    }
    toggleNavMenu(open){
        this.setState({
            is_open: open ? !this.state.is_open : false
        })
    }
    
    render(){
        return(
            <StaticQuery 
                query={graphql`
                    query MenuQuery {
                        allWordpressWpApiMenusMenusItems{
                            edges{
                                node{
                                    id
                                    name
                                    items{
                                            title
                                            url
                                            object_slug
                                            object
                                            object_id
                                    }
                                }
                            }
                        }
                    }`
                }
                render={data =>{
                    return(
                        <StyledMenu>
                            <div className={"header-menu-wrapper " + (this.state.is_open ? " menu-show" : "") }>
                                <ul className="header-menu">
                                    {
                                        data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map((item)=>(
                                            <MenuItem key={item.object_id} item={item} toggleNavMenu ={this.toggleNavMenu}/>
                                        ))
                                    }
                                </ul>
                            </div>
                            <div className="hamburger-menu-wrapper" onClick={() => this.toggleNavMenu(true)}>
                                <div className={"hamburger-menu" + (this.state.is_open ? " animate" : "")}></div>	  
                            </div>
                        </StyledMenu>
                        )
                }}
            />
        )
    }
}

export default HamburgerMenu