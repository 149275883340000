import React, {Component} from 'react'
import { Link } from 'gatsby'

// поправь ссылки для меню
// иначе то что сделано нчерез простую ссылку, не проходит

class MenuItem extends Component{
    render(){
        const item = this.props.item;
        const toggleNavMenu = this.props.toggleNavMenu;
        const submenuRender = function(submenus){
            if(submenus != null){
                return (
                    <ul>
                        {
                            submenus.map((item)=>{
                                return (<li key={item.object_id}>
                                            <Link 
                                                to={(item.object === 'page' || item.object === 'custom' ? item.object_slug : "/posts/" + item.object_slug)}
                                                onClick={() => toggleNavMenu(false)}>
                                                {item.title}
                                            </Link>
                                        </li>)
                            })
                        }
                    </ul>
                )
            }
            return null
        }
        return(
            <li key={item.object_id}>
                <Link 
                    to={(item.object === 'page' ? item.object_slug : (item.object === 'custom' ? item.url : "/posts/" + item.object_slug))}
                    onClick={() => toggleNavMenu(false)}>
                    {item.title}
                </Link>
                {submenuRender(item.wordpress_children)}
            </li>
            )
    }
}


export default MenuItem