import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Header from './header'
import '../css/normalize.css'
import '../css/style.css'
import styled from 'styled-components'


const StyledLayout = styled.main`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`
const StyledSection = styled.section`
  display:flex;
  flex-grow: 1;
  padding-top:52px;
  @media (max-width:767px){
    padding-top:70px;
  }
  @media (min-width:768px) and (max-width:991px){
    padding-top:70px;
  }
`

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <StyledLayout>
        <Header siteTitle={data.site.siteMetadata.title} />
        <StyledSection>
          {children}
        </StyledSection>
      </StyledLayout>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
