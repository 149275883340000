import React from 'react'
import { Link } from 'gatsby'
import logo from '../../../images/logo.svg'

export default ()=>{
    return(
        <div className="site-logo">
            <Link to='/'>
                <img src={logo} alt=""/>
            </Link>
        </div>
    )
}